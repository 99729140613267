@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;700&display=swap');
:root {
  --primary: #000000;
  --secondary: #4879ff;
}

h1,
h2,
h3 {
  margin: 0;
}

body {
  margin: 0;
  color: #333;
  font-family: 'Fira Sans', sans-serif;
  background: #f2f2f2;
}

nav {
  background: var(--primary);
  padding: 10px 20px;
  text-align: center;
}

nav a {
  color: #f2f2f2;
  margin: 10px;
  display: inline-block;
}

nav h1 {
  color: #fff;
}

.page {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
}

/* forms */
form {
  background: #fff;
  padding: 20px;
  max-width: 480px;
  margin: 0 auto;
  border-radius: 6px;
}

form input,
form textarea {
  display: block;
  width: 100%;
  padding: 6px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  margin: 10px 0 20px 0;
}

form button {
  background: var(--primary);
  color: #fff;
  border: 0;
  border-radius: 6px;
  padding: 6px 8px;
  font-family: "Poppins";
  cursor: pointer;
}

/* order-by buttons */
.order-by button {
  margin-right: 10px;
  background: var(--primary);
  color: #fff;
  border: 0;
  padding: 4px 8px;
  border-radius: 6px;
  font-family: "Poppins";
  cursor: pointer;
}

.map-container {
  justify-content: center;
  display: flex;
  /* or any desired height */
}

#map {
  height: 400px;
  /* or any desired height */
  width: 80%;
  /* or any desired width */
}

.lat-lng-display {
  text-align: center;
  margin: 20px auto;
  width: 50%;
}

.button-disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}